export const EVENTS_APP_DEF_ID = '140603ad-af8d-84a5-2c80-a0f60cb47351'
export const MEMBERS_APP_DEF_ID = '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9'
export const PAID_PLANS_APP_DEF_ID = '1522827f-c56c-a5c9-2ac9-00f9e6ae12d3'
export const LIVE_VIDEO_APP_DEF_ID = '2608697e-067a-44e2-8250-8e390df5bf71'
export const GROUPS_APP_DEF_ID = '148c2287-c669-d849-d153-463c7486a694'

export type ProgressBarConfig = {
  title: string
  step1: string
  step2: string
  step3: string
  timeBetweenSteps: number
}

export const BASE_INSTALL_CONFIG: ProgressBarConfig = {
  title: 'membersInstallProgressBarTitle',
  step1: 'membersInstallProgressBarFirstStep',
  step2: 'membersInstallProgressBarSecondStep',
  step3: 'membersInstallProgressBarThirdStep',
  timeBetweenSteps: 4500,
}

export const PROMO_INSTALL_CONFIG: ProgressBarConfig = {
  title: 'membersPromoInstallProgressBarTitle',
  step1: 'membersInstallProgressBarFirstStep',
  step2: 'membersInstallProgressBarSecondStep',
  step3: 'membersInstallProgressBarThirdStep',
  timeBetweenSteps: 4500,
}

export const PROMO_UPDATE_CONFIG: ProgressBarConfig = {
  title: 'membersPromoUpdateProgressBarTitle',
  step1: 'membersPromoUpdateProgressBarFirstStep',
  step2: 'membersInstallProgressBarSecondStep',
  step3: 'membersInstallProgressBarThirdStep',
  timeBetweenSteps: 3000,
}
